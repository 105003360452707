import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactList from 'react-list';
import { useFocusOnMount, withVisibilityLogic, ListNavigation } from 'mangools-react-components/src';

import HistoryPanelItem from 'components/panels/historyPanel/HistoryPanelItem';
import sadImg from 'images/sad.svg';
import HistoryType from 'types/HistoryType';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER } from 'constants/Shortcuts';

function HistoryPanel(props) {
    const closeRef = useFocusOnMount();
    const listRef = useRef(null);
    const [preselectedItemId, setPreselectedItemId] = useState(null);

    useEffect(() => {
        props.onOpen();
    }, []);

    useEffect(() => {
        if (props.data.length > 0) {
            setPreselectedItemId(props.data[0].key);
        }
    }, [props.data]);

    const handleItemClicked = (type, { query, locationId, languageId }) => {
        props.onClick(type, { query, languageId, locationId });
    };

    const handleArrowUp = ([historyItem, newIndex]) => {
        setPreselectedItemId(historyItem.key);
        listRef.current.scrollAround(newIndex - 3);
    };

    const handleArrowDown = ([historyItem, newIndex]) => {
        setPreselectedItemId(historyItem.key);
        listRef.current.scrollAround(newIndex + 3);
    };

    const handleEnter = () => {
        const item = props.data.find(entry => entry.key === preselectedItemId);

        setPreselectedItemId(null);
        props.onClick(item.type, {
            query: item.query,
            languageId: item.language.id,
            locationId: item.location.id,
        });
    };

    const renderEmptyListMessage = () => (
        <div className="uk-height-1-1 cursor-default">
            <div className="uk-flex uk-flex-middle uk-flex-center uk-text-center uk-height-1-1">
                <div>
                    <img className="mg-margin-b-15" src={sadImg} alt="Oh no" width="100" />
                    <br />
                    <strong className="font-18">Your history is empty</strong>
                    <p className="mg-margin-b-10">
                        Start your keyword research now, your recent searches will be listed here.
                    </p>
                    <button className="mg-btn is-link is-small" onClick={props.onClose} type="button" ref={closeRef}>
                        Close this panel
                    </button>
                </div>
            </div>
        </div>
    );

    const renderItems = (items, ref) => <div ref={ref}>{items}</div>;

    const renderItem = (index, _key) => {
        /* eslint-disable react/prop-types */
        const item = props.data[index];

        return (
            <HistoryPanelItem
                key={`${item.id}-${index}`}
                language={item.language}
                location={item.location}
                onClick={handleItemClicked}
                query={item.query}
                rank={item.rank}
                rankUpdatedAt={item.rankUpdatedAt}
                type={item.type}
                isPreselected={item.key === preselectedItemId}
            />
        );
        /* eslint-enable react/prop-types */
    };

    const renderPreloaderItem = () => (
        <div className="mg-results-tr uk-flex is-load" role="listitem">
            <div className="mg-results-td is-80 uk-flex uk-flex-middle">
                <span className="mg-preloader-block" style={{ width: '36px' }} />
            </div>
            <div className="mg-results-td">
                <span className="font-16">
                    <span className="mg-preloader-block uk-width-7-10" />
                </span>
                <div>
                    <span className="mg-preloader-block uk-width-2-10" />
                </div>
            </div>
        </div>
    );

    const renderPreloader = () => (
        <div>
            {renderPreloaderItem()}
            {renderPreloaderItem()}
            {renderPreloaderItem()}
        </div>
    );

    const renderBody = () => {
        if (props.fetching) {
            return renderPreloader();
        } else if (props.data.length > 0) {
            return (
                <ListNavigation
                    data={props.data}
                    selectedItemId={preselectedItemId}
                    itemId="key"
                    onArrowDownShortcut={handleArrowDown}
                    onArrowUpShortcut={handleArrowUp}
                    onEnterShortcut={handleEnter}
                    shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER]}
                    shortcuts={shortcuts}
                >
                    <ReactList
                        itemRenderer={renderItem}
                        itemsRenderer={renderItems}
                        length={props.data.length}
                        type="uniform"
                        updateWhenDataChange={props.data}
                        ref={listRef}
                    />
                </ListNavigation>
            );
        } else {
            return renderEmptyListMessage();
        }
    };

    return (
        <div className="mg-panel">
            <div className="mg-panel-title mg-padding-30 mg-border-b">
                <div className="uk-flex uk-flex-space-between">
                    <h1 className="uk-margin-remove font-24">
                        <FontAwesomeIcon icon="history" aria-hidden="true" className="mg-margin-r-10" />
                        <strong>Search history</strong>
                    </h1>
                    <button
                        className="mg-btn is-xsmall is-white-red mg-margin-r-15 uk-hidden-small"
                        disabled={props.fetching || props.data.length === 0}
                        onClick={props.onDeleteHistory}
                        type="button"
                    >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} aria-hidden="true" />
                        Clear history
                    </button>
                    {/* eslint-disable-next-line react/prop-types */}
                    <button className="mg-close" onClick={props.onClose} type="button" ref={closeRef}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                </div>
            </div>
            <div className="mg-panel-content">{renderBody()}</div>
        </div>
    );
}

HistoryPanel.propTypes = {
    data: arrayOf(HistoryType).isRequired,
    fetching: bool.isRequired,
    onClick: func.isRequired,
    onClose: func.isRequired,
    onOpen: func.isRequired,
    onDeleteHistory: func.isRequired,
};

export default withVisibilityLogic(React.memo(HistoryPanel));
