import React, { useCallback } from 'react';
import { func, string } from 'prop-types';
import classnames from 'classnames';
import { Favicon } from 'mangools-react-components/src';

import Tooltip from 'components/other/Tooltip';
import PlatformIcon from 'components/messages/TrackInSerpwatcherMessage/PlatformIcon';

import SerpwatcherTrackingType from 'types/SerpwatcherTrackingType';

function TrackingItem(props) {
    const { tracking } = props;

    const handleButtonClick = useCallback(() => {
        props.onClick(props.trackingId);
    }, [tracking]);

    return (
        <li
            className={classnames({
                'is-active': props.trackingId === props.selectedTrackingId,
            })}
        >
            <button aria-checked={false} onClick={handleButtonClick} role="menuitemradio" type="button" tabIndex="-1">
                <div className="uk-flex">
                    <div className="uk-width-5-10 mg-margin-r-10 uk-width-7-10">
                        <Favicon className="mg-margin-r-10" domain={tracking.domain} size={12} />
                        {tracking.domain}
                    </div>
                    {/* eslint-disable-next-line max-len */}
                    <div className="uk-width-5-10 uk-flex uk-flex-middle uk-flex-right font-10 uk-width-3-10">
                        <div className="mg-truncate uk-display-inline-block is-lightgrey mg-margin-r-10">
                            <Tooltip overlayContent={tracking.location.label} placement="top">
                                <span className="color-grey">{tracking.location.label}</span>
                            </Tooltip>
                        </div>
                        <PlatformIcon platformId={tracking.platformId} />
                    </div>
                </div>
            </button>
        </li>
    );
}

TrackingItem.propTypes = {
    tracking: SerpwatcherTrackingType.isRequired,
    trackingId: string.isRequired,
    onClick: func.isRequired,
    selectedTrackingId: string,
};

export default TrackingItem;
