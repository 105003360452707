import React, { useEffect, useCallback } from 'react';
import { func, bool, string } from 'prop-types';
import { withVisibilityLogic, useTrackMessageVisibilityEffect } from 'mangools-react-components/src';
import { useDispatch } from 'react-redux';

import MessageHolder from 'components/messages/MessageHolder';

import SerpwatcherTrackingsDataType from 'types/SerpwatcherTrackingsDataType';

import swImage from 'images/serpwatcher-icon.svg';

import Trackings from 'components/messages/TrackInSerpwatcherMessage/Trackings';
import { TrackingsWrapper } from 'components/messages/TrackInSerpwatcherMessage/TrackingsWrapper';

import { analyticsEvents } from 'constants/analytics';

import { gtmTrack } from 'actions/analyticsActions';

function TrackInSerpwatcherMessage(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        props.requestSerpwatcherTrackingsAction();
    }, []);

    const onGtmTrack = useCallback(
        payload => {
            dispatch(gtmTrack(payload));
        },
        [dispatch],
    );

    useTrackMessageVisibilityEffect(analyticsEvents.TRACK_IN_SW, onGtmTrack);

    return (
        <MessageHolder onClose={props.onClose}>
            <div>
                <div className="mg-modal-header">
                    <img src={swImage} style={{ height: '48px' }} alt="SERPWatcher logo" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Track in SERPWatcher</h3>
                    <TrackingsWrapper>
                        <Trackings
                            fetchingSerpwatcherTrackings={props.fetchingSerpwatcherTrackings}
                            serpwatcherTrackings={props.serpwatcherTrackings}
                            listId={props.listId}
                            onClose={props.onClose}
                        />
                    </TrackingsWrapper>
                </div>
            </div>
        </MessageHolder>
    );
}

TrackInSerpwatcherMessage.propTypes = {
    onClose: func.isRequired,
    fetchingSerpwatcherTrackings: bool.isRequired,
    serpwatcherTrackings: SerpwatcherTrackingsDataType,
    requestSerpwatcherTrackingsAction: func.isRequired,
    listId: string,
};

export default withVisibilityLogic(React.memo(TrackInSerpwatcherMessage));
