import React, { useRef } from 'react';
import { bool, arrayOf, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, ListNavigation } from 'mangools-react-components/src';
import classnames from 'classnames';
import ReactList from 'react-list';
import { isNil } from 'ramda';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/lib/constants/Shortcuts';

import KeywordListItem from 'components/messages/addToListMessage/KeywordListItem';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER, SEARCH } from 'constants/Shortcuts';
import { DEFAULT_DEBOUNCE_MS } from 'constants/Other';

import ListType from 'types/ListType';

const KeywordListItems = props => {
    const inputRef = useRef(null);
    const listRef = useRef(null);
    const ulRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClearSearchInput = () => {
        inputRef.current.blur();
        props.onSearchChange('');
    };

    const isButtonDisabled = () => {
        if (props.fetchingLists) {
            return true;
        }

        if (isNil(props.selectedListId) || !props.meetsKwLimit) {
            return true;
        }

        const isListInFiltered = props.data.find(list => list.id === props.selectedListId);

        if (isNil(isListInFiltered)) {
            return true;
        }

        return false;
    };

    const handleAddToExistingList = () => {
        if (!isButtonDisabled()) {
            props.onSubmit(props.selectedListId);
        }
    };

    const isEnterShortcutDisabled = () =>
        document.activeElement === inputRef.current ||
        document.activeElement === buttonRef.current ||
        document.activeElement === ulRef.current ||
        (ulRef.current && ulRef.current.contains(document.activeElement));

    const handleArrowDown = ([list, index]) => {
        props.onClick(list.id);
        listRef.current.scrollAround(index + 2);
    };

    const handleArrowUp = ([list, index]) => {
        props.onClick(list.id);
        listRef.current.scrollAround(index - 2);
    };

    const handleSearchShortcut = e => {
        e.preventDefault();

        inputRef.current.focus();
    };

    const handleSearchChange = e => props.onSearchChange(e.target.value);

    const renderItems = (items, ref) => (
        <ul
            ref={r => {
                ulRef.current = r;
                ref(r);
            }}
            aria-label="Available lists to add keywords"
            role="menu"
            className="mg-listnav is-small kw-currency-list font-14"
            tabIndex={0}
        >
            {items}
        </ul>
    );

    const renderItem = index => {
        const entry = props.data[index];

        return (
            <KeywordListItem
                key={entry.id}
                selectedListId={props.selectedListId}
                list={entry}
                onClick={props.onClick}
            />
        );
    };

    const renderListBody = () => {
        const resultsLength = props.data.length;

        if (resultsLength === 0) {
            return (
                <div className="mg-border-b font-14 uk-text-left mg-padding-0-15" style={{ lineHeight: '48px' }}>
                    Sorry, no results here
                </div>
            );
        } else {
            return (
                <ReactList
                    itemRenderer={renderItem}
                    itemsRenderer={renderItems}
                    length={resultsLength}
                    type="uniform"
                    updateWhenDataChange={props.data}
                    ref={listRef}
                />
            );
        }
    };

    return (
        <ListNavigation
            data={props.data}
            selectedItemId={props.selectedListId}
            onArrowDownShortcut={handleArrowDown}
            onArrowUpShortcut={handleArrowUp}
            onEnterShortcut={handleAddToExistingList}
            isEnterShortcutDisabled={isEnterShortcutDisabled}
            onSearchShortcut={handleSearchShortcut}
            shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER, SEARCH]}
            shortcuts={shortcuts}
        >
            <div className="uk-position-relative mg-card">
                <Input
                    autoFocus
                    className="mg-input is-search in-dropdown font-14"
                    debounceMs={DEFAULT_DEBOUNCE_MS}
                    inputRef={inputRef}
                    onChange={handleSearchChange}
                    onClear={handleClearSearchInput}
                    placeholder="Search for a list..."
                    spellCheck={false}
                    type="text"
                    value={props.search}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                />
                <button
                    tabIndex="-1"
                    className={classnames('mg-icon-btn', 'uk-position-top-right', 'font-10', 'uk-height-1-1', {
                        'uk-hidden': props.search.length === 0,
                    })}
                    onClick={handleClearSearchInput}
                    type="button"
                    style={{ zIndex: 2, width: '36px' }}
                >
                    <FontAwesomeIcon icon="times" aria-label="Clear" />
                </button>
            </div>
            <div className="mg-card is-focusable uk-flex-item-auto uk-overflow-container" tabIndex="-1">
                {renderListBody()}
            </div>
            <button
                className="mg-btn is-green uk-width-1-1 mg-padding-0-15 mg-margin-t-15 uk-flex-item-none"
                disabled={isButtonDisabled()}
                onClick={handleAddToExistingList}
                type="button"
                ref={buttonRef}
            >
                {props.fetchingLists ? 'Adding keywords...' : 'Add keywords'}
            </button>
        </ListNavigation>
    );
};

KeywordListItems.propTypes = {
    data: arrayOf(ListType).isRequired,
    onClick: func.isRequired, // eslint-disable-line react/no-unused-prop-types
    selectedListId: string, // eslint-disable-line react/no-unused-prop-types
    onSearchChange: func.isRequired,
    search: string.isRequired,
    onSubmit: func.isRequired,
    meetsKwLimit: bool.isRequired,
    fetchingLists: bool.isRequired,
};

export default KeywordListItems;
