import React from 'react';
import { string } from 'prop-types';
import { Image } from 'mangools-react-components/src';
import { useSelector } from 'react-redux';

import Strings from 'constants/Strings';

import juice from 'images/juice-preloader.svg';
import juiceDark from 'images/juice-preloader-dark.svg';

import { colorSchemeSelector } from 'selectors/uiSelectors';

/* eslint-disable max-len */

function Preloader(props) {
    const colorScheme = useSelector(state => colorSchemeSelector(state));

    return (
        <div className="uk-position-relative uk-text-center mg-app-load-container">
            <svg
                x="0px"
                y="0px"
                width="155.9px"
                height="172px"
                viewBox="100.8 189 105.9 162"
                enableBackground="new 100.8 189 155.9 172"
                xmlSpace="preserve"
                className="uk-position-absolute uk-position-z-index"
            >
                <line
                    fill="none"
                    stroke="#FFFFFF"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="125.5"
                    y1="268.7"
                    x2="137.3"
                    y2="338.5"
                />
            </svg>
            <div className="mg-preloader-mask" />
            <Image
                darkSrc={juiceDark}
                lightSrc={juice}
                colorScheme={colorScheme}
                alt="Loading..."
                width="156"
                height="172"
                style={{ height: '172px' }}
            />
            <div className="color-grey mg-margin-t-15 uk-text-center font-montserrat">{props.text}</div>
        </div>
    );
}
/* eslint-enable max-len */

Preloader.propTypes = {
    text: string,
};

Preloader.defaultProps = {
    text: Strings.components.common.loading,
};

export default Preloader;
