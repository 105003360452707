import includes from 'ramda/src/includes';
import isNil from 'ramda/src/isNil';

/* global chrome */

const formatLocale = (locale) => {
    return locale ? locale.replace('_', '-') : locale;
};

const getBrowserLocale = () => {
    let locale = null;

    if (typeof chrome !== 'undefined' && chrome.runtime && typeof chrome.runtime.getManifest === 'function') {
        const manifestLocale = chrome.runtime.getManifest()?.current_locale;
        if (manifestLocale) return manifestLocale;
    }

    locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    if (!locale && navigator.userAgent) {
        const match = navigator.userAgent.match(/;.(\w+-\w+)/i);
        if (match) return match[1];
    }

    if (!locale && typeof Intl !== 'undefined' && typeof Intl.DateTimeFormat === 'function') {
        locale = Intl.DateTimeFormat().resolvedOptions().locale;
    }

    return locale;
};

const BROWSER_LOCALE = formatLocale(getBrowserLocale());

const NON_COMMA_LOCALE_SUBTAGS = ['he'];

const getLocaleSubtag = (fullLocale) => {
    if (!isNil(fullLocale)) {
        return fullLocale.split('-')[0];
    } else {
        return null;
    }
};

const BROWSER_LOCALE_SUBTAG = getLocaleSubtag(BROWSER_LOCALE);
export const IS_NON_COMMA_LOCALE = includes(BROWSER_LOCALE_SUBTAG, NON_COMMA_LOCALE_SUBTAGS);
export default BROWSER_LOCALE;
