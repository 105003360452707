import Defaults from '../constants/Defaults';
class RankTranslatorService {
  /**
   * Get textual representation of rank value.
   *
   * @param {rank: number} Keyword rank value 0-100.
   * @return {string} Textual representation of rank value.
   */
  static translate(rank) {
    if (rank > 100 || rank < 0 || rank === Defaults.NO_VALUE) {
      return 'unknown';
    } else {
      switch (true) {
        case rank > 84:
          // 100 - 85 (red) - 15 points
          return "don't do it";
        case rank > 69:
          // 70 - 84 (red) - 14 points
          return 'very hard';
        case rank > 49:
          // 50 - 69 (mangools) - 19 points
          return 'hard';
        case rank > 29:
          // 30 - 49 (orange) - 19 points
          return 'possible';
        case rank > 14:
          // 15 - 29 (bright green) - 19 points
          return 'still easy';
        case rank >= 0:
          // 0 - 14 (green) - 14 point
          return 'easy';
        default:
          return 'unknown';
      }
    }
  }
}
export default RankTranslatorService;