import React, { useState, useRef, useEffect } from 'react';
import { func, arrayOf, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, ListNavigation, withVisibilityLogic } from 'mangools-react-components/src';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import ReactList from 'react-list';
import { filter, isNil } from 'ramda';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/lib/constants/Shortcuts';

import LocationFlag from 'components/other/LocationFlag';
import MessageHolder from 'components/messages/MessageHolder';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER, SEARCH } from 'constants/Shortcuts';

import CurrencyType from 'types/CurrencyType';

function CurrencyMessage(props) {
    const [preselectedCurrency, setPreselectedCurrency] = useState(props.currencies[0]);
    const [search, setSearch] = useState('');
    const inputRef = useRef(null);
    const listRef = useRef(null);

    const lowerCasedSearch = search.toLowerCase();

    const currencies = filter(
        ({ code, name }) =>
            code.toLowerCase().includes(lowerCasedSearch) || name.toLowerCase().includes(lowerCasedSearch),
        props.currencies,
    );

    useEffect(() => {
        if (currencies.length > 0) {
            setPreselectedCurrency(currencies[0]);
        } else {
            setPreselectedCurrency(props.currencies[0]);
        }
    }, [search]);

    const handleClick = (currency = preselectedCurrency) => {
        props.onClose();

        if (props.currency.code !== currency.code) {
            props.onSetCurrency(currency);
            Alert.info(`Default CPC currency changed to <strong>${currency.name}</strong>`, { html: true });
        }
    };

    const handleClearSearchInput = () => {
        inputRef.current.blur();
        setSearch('');
    };

    const handleChange = e => setSearch(e.target.value);

    const handleArrowDown = ([currency, index]) => {
        setPreselectedCurrency(currency);
        listRef.current.scrollAround(index + 2);
    };

    const handleArrowUp = ([currency, index]) => {
        setPreselectedCurrency(currency);
        listRef.current.scrollAround(index - 2);
    };

    const handleSearchShortcut = () => {
        inputRef.current.focus();
    };

    const renderItems = (items, ref) => (
        <ul
            aria-label="Currency list"
            role="menu"
            ref={ref}
            className="mg-listnav is-small uk-overflow-container kw-currency-list font-14 uk-height-1-1 mg-card"
        >
            {items}
        </ul>
    );

    const renderCurrencies = () => {
        const renderItem = index => {
            const currency = currencies[index];

            return (
                <li
                    key={currency.code}
                    className={classnames({
                        'is-active': currency.code === props.currency.code,
                        'is-preselected': currency.code === preselectedCurrency.code,
                    })}
                >
                    <button
                        aria-checked={currency.code === props.currency.code}
                        onClick={() => handleClick(currency)}
                        role="menuitemradio"
                        type="button"
                        tabIndex="-1"
                    >
                        <LocationFlag label={currency.name} code={currency.countryCode.toLowerCase()} />
                        {currency.code} - {currency.name}
                    </button>
                </li>
            );
        };

        if (currencies.length > 0) {
            return (
                <ListNavigation
                    data={currencies}
                    selectedItemId={isNil(preselectedCurrency) ? null : preselectedCurrency.code}
                    itemId="code"
                    onArrowDownShortcut={handleArrowDown}
                    onArrowUpShortcut={handleArrowUp}
                    onEnterShortcut={handleClick}
                    onSearchShortcut={handleSearchShortcut}
                    shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER, SEARCH]}
                    shortcuts={shortcuts}
                >
                    <ReactList
                        itemRenderer={renderItem}
                        itemsRenderer={renderItems}
                        length={currencies.length}
                        type="uniform"
                        updateWhenDataChange={currencies}
                        ref={listRef}
                    />
                </ListNavigation>
            );
        } else {
            return (
                <div
                    className="mg-border-b font-14 uk-text-left mg-padding-0-15"
                    style={{ lineHeight: '48px', pointerEvents: 'none' }}
                >
                    Sorry, no results here
                </div>
            );
        }
    };

    const renderBody = () => {
        if (props.currenciesFetching) {
            return (
                // eslint-disable-next-line max-len
                <ul className="mg-listnav is-small uk-overflow-container kw-currency-list font-14 uk-height-1-1 mg-card">
                    <li>
                        <div>
                            <div className="mg-preloader-block uk-width-3-4" />
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className="mg-preloader-block uk-width-2-3" />
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className="mg-preloader-block uk-width-3-4" />
                        </div>
                    </li>
                </ul>
            );
        } else {
            return (
                <>
                    <div className="uk-position-relative">
                        <Input
                            autoFocus
                            className="mg-input is-search in-dropdown font-14"
                            onChange={handleChange}
                            onClear={handleClearSearchInput}
                            placeholder="Search for a currency..."
                            inputRef={inputRef}
                            spellCheck={false}
                            type="text"
                            value={search}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                        />
                        <button
                            className={classnames('mg-icon-btn', 'uk-position-top-right', 'font-10', 'uk-height-1-1', {
                                'uk-hidden': search.length === 0,
                            })}
                            onClick={handleClearSearchInput}
                            type="button"
                            style={{ zIndex: 2, width: '36px' }}
                        >
                            <FontAwesomeIcon icon="times" aria-label="Clear" />
                        </button>
                    </div>
                    <div className="mg-card is-focusable uk-flex-item-auto uk-overflow-container">
                        {renderCurrencies()}
                    </div>
                </>
            );
        }
    };

    return (
        <MessageHolder onClose={props.onClose}>
            <div>
                <div className="mg-modal-header">
                    <FontAwesomeIcon icon="coins" className="color-orange" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Set preferred currency</h3>
                    <div className="kw-addtolist-container uk-flex uk-flex-column">{renderBody()}</div>
                </div>
            </div>
        </MessageHolder>
    );
}

CurrencyMessage.propTypes = {
    onClose: func.isRequired,
    onSetCurrency: func.isRequired,
    currency: CurrencyType.isRequired,
    currencies: arrayOf(CurrencyType),
    currenciesFetching: bool.isRequired,
};

export default withVisibilityLogic(React.memo(CurrencyMessage));
