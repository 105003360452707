import Defaults from '../constants/Defaults';
import Colors from '../constants/Colors';

/**
 * Service about Keyword Rank color mappings.
 */
class RankColorizerService {
  /**
   * Get a color name or hex number for specified rank value.
   *
   * @param {rank: number} Keyword rank 0-100.
   * @param {hex: bool, default: false} Flag to indicate You want a hex value.
   * @return {string} Hex or normal color name for specified rank.
   */
  static getColor(rank, hex = false, isDarkMode = false) {
    if (rank === Defaults.NOT_AVAILABLE) {
      return this.noRankColor(hex);
    } else {
      switch (true) {
        case rank > 69:
          // 100 - 75
          return this.redColor(hex, isDarkMode);
        case rank > 49:
          // 55 - 74
          return this.orangeColor(hex, isDarkMode);
        case rank > 29:
          // 35 - 54
          return this.yellowColor(hex, isDarkMode);
        case rank > 14:
          // 15 - 34
          return this.lightGreenColor(hex, isDarkMode);
        case rank >= 0:
          // 0 - 14
          return this.greenColor(hex, isDarkMode);
        default:
          return this.noRankColor(hex);
      }
    }
  }
  static redColor(hex, isDarkMode) {
    if (hex) {
      return isDarkMode ? Colors.RED_DARKMODE : Colors.RED;
    } else {
      return 'red';
    }
  }
  static orangeColor(hex, isDarkMode) {
    if (hex) {
      return isDarkMode ? Colors.ORANGE_DARKMODE : Colors.ORANGE_SATURATED;
    } else {
      return 'orange';
    }
  }
  static yellowColor(hex, isDarkMode) {
    if (hex) {
      return isDarkMode ? Colors.MAIN_COLOR_DARKMODE : Colors.MAIN_COLOR;
    } else {
      return 'yellow';
    }
  }
  static lightGreenColor(hex, isDarkMode) {
    if (hex) {
      return isDarkMode ? Colors.FRESH_GREEN_DARKMODE : Colors.FRESH_GREEN;
    } else {
      return 'lightgreen';
    }
  }
  static greenColor(hex, isDarkMode) {
    if (hex) {
      return isDarkMode ? Colors.GREEN_DARKMODE : Colors.GREEN;
    } else {
      return 'green';
    }
  }
  static noRankColor(hex) {
    if (hex) {
      return Colors.GREY;
    } else {
      return 'no-rank';
    }
  }
}
export default RankColorizerService;