import update, { extend } from 'immutability-helper';
import { omit, filter, includes, isNil } from 'ramda';

import ActionTypes from 'constants/ActionTypes';
import DataSourceTypes from 'constants/DataSourceTypes';

import ResultDataService from 'services/ResultDataService';

extend('$clearPatchedAction', (actionType, patches) => omit([actionType], patches));

const initialState = {
    // NOTE:
    // For checking if we already have fetched
    // competitor data (organic and paid are in same req).
    // This is not caching mechanism.
    currentDataParams: {
        locationId: null,
        query: null,
        source: null,
        urlType: null,
    },
    data: [],
    error: {
        status: null,
        text: null,
        type: null,
    },
    fakeResultsDisabled: false,
    fetching: false,
    rateLimited: false,
    rankFetching: [],
    patches: {},
    lastPayload: {}, // for action retrying, e.g. import request failed
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_IMPORT_FETCHING:
        case ActionTypes.DATA_LISTS_KEYWORDS_FETCHING:
        case ActionTypes.DATA_RESULTS_GOOGLE_FETCHING:
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_FETCHING:
        case ActionTypes.DATA_RESULTS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            return update(state, {
                fakeResultsDisabled: {
                    $set: false,
                },
                data: {
                    $set: ResultDataService.calculateSearchVolumeTimeframes(action.payload.data),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
                rateLimited: {
                    $set: action.payload.meta.rateLimited || false,
                },
                currentDataParams: {
                    $set: initialState.currentDataParams,
                },
            });
        }
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_RECEIVED: {
            return update(state, {
                fakeResultsDisabled: {
                    $set: false,
                },
                data: {
                    $set: ResultDataService.calculateSearchVolumeTimeframesForCompetitor(action.payload.data.keywords),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
                rateLimited: {
                    $set: false,
                },
                currentDataParams: {
                    locationId: { $set: action.payload.params.location.id },
                    query: { $set: action.payload.params.query },
                    source: { $set: DataSourceTypes.MANGOOLS_COMPETITORS },
                    urlType: { $set: action.payload.params.urlType },
                },
            });
        }
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_SKIPPED: {
            return update(state, {
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED: {
            return update(state, {
                fakeResultsDisabled: {
                    $set: true,
                },
                data: {
                    $set: ResultDataService.calculateSearchVolumeTimeframes(action.payload),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
                currentDataParams: {
                    $set: initialState.currentDataParams,
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC: {
            return update(state, {
                fakeResultsDisabled: { $set: true },
                error: {
                    $set: initialState.error,
                },
                data: {
                    $apply: keywords => filter(({ id }) => !includes(id, action.payload.keywordIds), keywords),
                },
                patches: {
                    $merge: {
                        [ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT]: state.data,
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT: {
            if (!isNil(state.patches[action.type])) {
                return update(state, {
                    data: { $merge: state.patches[action.type] },
                    patches: { $clearPatchedAction: action.type },
                });
            } else {
                return state;
            }
        }

        case ActionTypes.DATA_IMPORT_RECEIVED: {
            return update(state, {
                fakeResultsDisabled: {
                    $set: true,
                },
                data: {
                    $set: ResultDataService.calculateSearchVolumeTimeframes(action.payload.data),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
                rateLimited: {
                    $set: action.payload.meta.rateLimited || false,
                },
                currentDataParams: {
                    $set: initialState.currentDataParams,
                },
            });
        }
        case ActionTypes.DATA_IMPORT_ERROR:
        case ActionTypes.DATA_LISTS_KEYWORDS_ERROR:
        case ActionTypes.DATA_RESULTS_GOOGLE_ERROR:
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_ERROR:
        case ActionTypes.DATA_RESULTS_ERROR: {
            return update(state, {
                fakeResultsDisabled: { $set: initialState.fakeResultsDisabled },
                data: { $set: initialState.data },
                fetching: { $set: initialState.fetching },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
                lastPayload: { $set: action.payload.lastPayload },
            });
        }
        case ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_ERROR: {
            return update(state, {
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_RESULTS_KEYWORD_RANK_RECEIVED: {
            return update(state, {
                data: {
                    $apply: data =>
                        data.map(kw => {
                            if (kw.id === action.payload.id) {
                                return update(kw, {
                                    rank: {
                                        $set: action.payload.rank,
                                    },
                                    rankUpdatedAt: {
                                        $set: action.payload.rankUpdatedAt,
                                    },
                                });
                            } else {
                                return kw;
                            }
                        }),
                },
                rankFetching: {
                    $apply: rankFetching => filter(id => id !== action.payload.id, rankFetching),
                },
            });
        }
        case ActionTypes.DATA_RESULTS_KEYWORD_RANK_EMPTY: {
            return update(state, {
                rankFetching: {
                    $apply: rankFetching => filter(id => id !== action.payload.id, rankFetching),
                },
            });
        }
        case ActionTypes.DATA_RESULTS_GOOGLE_EMPTY: {
            return update(state, {
                $set: initialState,
            });
        }
        case ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_PROCEED: {
            return update(state, {
                rankFetching: {
                    $set: action.payload,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default resultsReducer;
